import * as React from "react"
import { Container, Row, Col, Form  } from "react-bootstrap"
import {Link}  from "gatsby"

import Seo from "../../../../components/seo"
import Header from '../../../../components/Header/Header';
import Footer from '../../../../components/Footer/Footer';
import Valuation from "../../../../components/Valuation/ValuationForm/InstantValuation"


const InstantValuation = () => (
    <div className="wrapper home-visit-valuation-page">
    <Header/>
      <Seo title="Wards | Instant Valuation" description="Looking to see how much your home in the Kent area is worth? Instantly discover how much your property is worth with our 30 second instant, online valuation. " />
      <Valuation />
      <Footer />
    </div>
  )
  
  export default InstantValuation
  